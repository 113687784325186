import React, { useEffect } from "react";
import { ListBuilder } from '@eds/vanilla/list-builder/ListBuilder';
import { useState } from "react";

const EuiListBuilder = (props) => {

    useEffect(() => {
        if (props.id) {
            let data = [];
            if (props.data && props.data.length > 0) {
                data = [...props.data];
                data.map(item => {
                    item.title = item.text ?? item.name;
                });
                const element = document.querySelector('#' + props.id);
                if (element) {
                    const component = new ListBuilder(element, data);             
                    component.init();
                    const addButton = document.querySelector('#' + props.addButtonId);
                    if (addButton.getAttribute('listener') != 'true') {
                        addButton.addEventListener("click", (e) => {
                            const elementClicked = e.target;
                            elementClicked.setAttribute('listener', 'true');
                            props.addSelectedItems && props.addSelectedItems(e, component.output);
                        });
                    }
                    const removeButton = document.querySelector('#' + props.removeButtonId);
                    if (removeButton.getAttribute('listener') != 'true') {
                        removeButton.addEventListener("click", (e) => {
                            const elementClicked = e.target;
                            elementClicked.setAttribute('listener', 'true');
                            props.addSelectedItems && props.addSelectedItems(e, component.output);
                        });
                    }
                }
            }
        }
        else {
            console.error("You should set an id to Listbuilder component");
        }
    }, [props.data]);


    return (<div className="list-builder m-1" id={props.id} >
        <div className="lb-block lb-main">
            <div className="lb-header">
                <div className="title" style={{ fontSize: 15, marginBottom: 5 }}>{props.availableItem?.title}</div>
                <div className="lb-filter">
                    <input type="text" className="lb-filter-input lb-filter-main" placeholder="Type to search" />
                </div>
                <div className={"lb-controls " + (props.availableItem?.hideSelectAll ? "overlay-hidden" : "")}>
                    <div className="lb-header-cb">
                        <input type="checkbox" className="lb-primary-cb-all" id="lb-primary-cb-all" />
                        <label htmlFor="lb-primary-cb-all">Select all items</label>
                    </div>

                    <div className="lb_header-counters">
                        <span className="lb-counter-total">0</span> | <span className="lb-counter-selected">0</span> selected
                    </div>
                </div>
            </div>
            <div className="lb-list">
                <p className="lb-placeholder">The list is empty.</p>
            </div>
        </div>

        <div className="lb-transfer-btns">
            <button id={props.addButtonId} className="btn lb-add-btn" disabled><i className="icon icon-chevron-right"></i></button>
            <button id={props.removeButtonId} className="btn lb-remove-btn" disabled><i className="icon icon-chevron-left"></i></button>
        </div>

        <div className="lb-block lb-secondary">
            <div className="lb-header">
                <div className="title" style={{ fontSize: 15, marginBottom: 5 }} >{props.assignedItem?.title}</div>
                <div className="lb-filter">
                    <input type="text" className="lb-filter-input lb-filter-secondary" placeholder="Type to search" />
                </div>
                <div className={"lb-controls " + (props.assignedItem?.hideSelectAll ? "overlay-hidden" : "")}>
                    <div className="lb-header-cb">
                        <input type="checkbox" className="lb-secondary-cb-all" id="lb-secondary-cb-all" />
                        <label htmlFor="lb-secondary-cb-all">Select all items</label>
                    </div>
                    <div className="lb_header-counters">
                        <span className="lb-counter-total">0</span> |  <span className="lb-counter-selected">0</span> selected
                    </div>
                </div>
            </div>
            <div className="lb-list">
                <p className="lb-placeholder">The list is empty.</p>
            </div>
        </div>
    </div>)
}

export default EuiListBuilder;