import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import EuiTextField from "../../eui-components/textfield";
import EuiTextArea from "../../eui-components/textarea";
import EuiSelect from "../../eui-components/select";
import EuiMultiSelect from "../../eui-components/select/multiSelect";
import FormField from "../../eui-components/formfield";
import EuiDatepicker from "../../eui-components/datepicker";
import EuiCheckbox from "../../eui-components/checkbox";
import ApiClient from '../../utils/api-client';
import DateUtils from "../../utils/date-utils";
import AspHelper from "../../utils/asp-helper";
import { Notification } from '@eds/vanilla';
import constants from "../../utils/constants";
import CommonHelper from "../../utils/common-helper";
import EuiDialog from "../../eui-components/dialog";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import PageHelper from "../../utils/page-helper";
import SasHelper from "../../utils/sas-helper";

class CreateEditResource extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.cvInputRef = React.createRef();
        this.state = {
            legalStatusOptions: [{ value: 1, text: "On Sponsership" }, { value: 2, text: "Contracted" }],
            statusOptions: [{ value: 1, text: "Created" }, { value: 2, text: "Submitted" },
            { value: 3, text: "Approved" }, { value: 4, text: "Rejected" }, { value: 5, text: "Suspended" }],
            teamLeadOptions: [{ value: 1, text: "Yes" }, { value: 0, text: "No" }],
            resource: {},
            aspCompanies: [],
            customers: [],
            Ids: [],
            jobs: [],
            jobList: [],
            imagePath: [],
            cvPath: this.props.aspResourcesData.resource.cvPath || "",
            cvName: this.props.aspResourcesData.resource.cvName || "",
            imageName: "",
            isLoading: false,
            countries: [],
            disableSave: false,
            imgPreview: "",
            Done: true,
        }
    }
    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        const userCompanies = this.props.userData.userDetail?.userAspCompanies?.map(item => item.aspCompany.countryId);
        var companiesCountries = this.props.userData.countries.filter(item => userCompanies.includes(item.value));

        this.setState({
            resource: this.props.aspResourcesData.resource,
            jobs: this.props.aspResourcesData.resource.jobs,
            jobList: this.props.aspResourcesData.resource.jobList,
            countries: this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ?
                companiesCountries
                : this.props.userData.countries,
            aspCompanies: [...this.props.aspResourcesData.companies.filter(item => item.countryId === this.props.aspResourcesData.resource.companyCountry)],
            customers: [...this.props.userData.customers?.filter(item => item.countryId === this.props.aspResourcesData.resource.companyCountry)]
        })
    }
    handleMultiSelect = (e) => {
        const target = e.target;
        var list = this.state.Ids;
        list.push(target);
        this.setState({
            Ids: list
        })
    }
    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const text = target.text;
        const filter = { ...this.props.aspResourcesData.resource };
        if (name == "companyCountry") {
            var country = [...this.props.userData.countries.filter(c => c.value == value)];
            var temp = [];
            var companies = [...this.props.aspResourcesData.companies];
            var customers = [... this.props.userData.customers?.filter(c => c.countryId === value)];
            companies.forEach(c => {
                if (c.countryId == e.target.value)
                    temp.push(c);
            });
            filter[name] = value || "";
            filter["companyCountryName"] = text;
            this.setState({
                aspCompanies: temp,
                customers: customers
            });
        }
        else if (name == "countryId") {
            filter["countryId"] = value === 0 ? null : value;
            filter["country"] = text;
        }
        else if (name === "aspCompanyId") {
            filter["aspCompanyId"] = value || "";
            filter["aspCompany"] = text;
        }
        else if (name === "customerId") {
            filter["customerId"] = value || "";
            filter["customer"] = text;
        }
        else if (name == "status") {
            var status = this.props.aspResourcesData.statuses.find(item => item.value == value);
            filter["status"] = status.text;
        }
        else if (name == "jobs") {
            var jobResource = this.props.aspResourcesData.jobs.filter(j => j.value == value)[0];
            var date = DateUtils.getUtcDate();
            var listElement = {
                resourceId: this.props.aspResourcesData.resource.id,
                jobId: value,
                createDate: date,
                createUser: parseInt(this.props.userData.userData.id)
            }
            if (filter["jobList"].findIndex(e => e.jobId == listElement.jobId) == -1) {
                var jobList = [...filter["jobList"]];
                var jobs = [...filter["jobs"]];
                jobList.push(listElement);
                jobs.push(target.text);
                filter["jobList"] = jobList;
                filter["jobs"] = jobs;
            }
        }
        else
            filter[name] = value || "";
        this.props.setAspResource(filter);
    }
    Cancel = () => {
        var resource = { ... this.state.resource };
        this.props.setAspResource({});
        resource["jobs"] = this.state.jobs;
        resource["jobList"] = this.state.jobList;
        this.props.setAspResource(resource);
        this.props.createEditResourceForm(false);
        this.props.setCreateResource(true);
    }
    removeImg = () => {
        this.setState({
            imgPreview: "",
            imagePath: [],
            imageName: "",
        });
    }
    imageChange = (e) => {
        const files = e.target.files;
        let reader = new FileReader();
        if (files.length !== 0) {
            reader.onload = () => {
                const data = reader.result;
                this.setState({
                    imgPreview: data,
                    imagePath: data,
                    imageName: files[0].name,
                });
            };
            reader.readAsDataURL(files[0]);
        }
        
    }
    onCVChange = (e) => {
        let today = new Date();
        const files = e.target.files;
        let reader = new FileReader();
        if (files.length !== 0) {
            reader.onload = () => {
                const data = reader.result;
                this.setState({
                    cvPath: data,
                    cvName: files[0].name, // unique ID is generated via the backend
                });
            };
            reader.readAsDataURL(files[0]);
        }
    }
    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        else {
            filter.id = parseInt(this.props.aspResourcesData.resource?.id ?? "0");
            filter.idsList = [];
            filter.idsList.push(filter.id);
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var resourceProjects = response.data.projects;
                        var certificates = [];
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (new Date(c.validUntil).getTime() - date.getTime() > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries?.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                            inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                           /* if (e.status == "Active" && jobs.length) {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                            if (inCompletedCerts) {
                             inCompletedCerts.forEach(c => {
                                 if (!c.valid)
                                     test = false;
                             });
                         }
                         validResource = test

                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? SasHelper.addSasKey(constants.profileStorage + blob[0].key , this.props.sasToken)  : ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        if (selectedResource)
                            this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(1);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })
                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });
    }
    SaveChanges = () => {
        this.props.setSelectedItem(1);
        var _pills = document.getElementsByClassName("pill");
        var pills = [];
        for (let p of _pills) {
            pills.push(p.innerHTML)
        }
        const resource = { ...this.props.aspResourcesData.resource };
        var newJobs = [];
        if (pills.length == 0 || !pills) {
            resource["jobList"] = [];
            newJobs = [];
        }
        else {
            resource["jobs"] = pills;
            var requiredJobList = this.props.aspResourcesData.jobs.filter(item => pills.includes(item.text));
            var requiredJobIds = requiredJobList.map(item => item.value);
            var jobList = [...resource['jobList']];
            jobList.forEach(e => {
                if (requiredJobIds.includes(e.value) || requiredJobIds.includes(e.jobId)) {
                    if (!e.jobId)
                        e.jobId = e.value;
                    if (newJobs.findIndex(item => item.jobId == e.jobId) == -1)
                        newJobs.push(e);
                }
            })
            resource['jobList'] = newJobs;
        }
        this.props.setAspResource(resource)
        const list = newJobs;
        const mappedList = list?.map(item => parseInt(item.jobId));
 
        console.log ("Mapped List " + JSON.stringify(mappedList))
        const teamLeader = (this.props.aspResourcesData.resource.teamLead == true
            || this.props.aspResourcesData.resource.teamLead == 1 ? true : false)
        if (mappedList?.includes(constants.jobsDict.EHS_Auditor) && (mappedList?.length > 1 || teamLeader )) {
            const notification = new Notification({
                title: 'Save Resource',
                description: 'EHS Auditor Cannot be assigned with team leader any other job role.',
                //timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (mappedList?.includes(constants.jobsDict.Driver) && mappedList?.includes(constants.jobsDict.Rigger)) {
            const notification = new Notification({
                title: 'Save Resource',
                description: 'Driver and Rigger Job roles cannot be assigned together, please delete any one job and save again',
                //timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (!this.props.aspResourcesData.resource.name || !this.props.aspResourcesData.resource.email
            || !this.props.aspResourcesData.resource.lineManagerEmail ||
            !this.props.aspResourcesData.resource.identificationTypeId || !this.props.aspResourcesData.resource.identificationNumber ||
            !this.props.aspResourcesData.resource.aspCompanyId || !this.props.aspResourcesData.resource.status || !this.props.aspResourcesData.resource.customerId ||
            !pills.length
        ) {
            const notification = new Notification({
                title: 'Save Resource',
                description: 'Mandatory fields need to be filled to create resource.',
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
        }
        else {
            /* const signumCheck = UserAdminHelper.isValidResourceSignum(this.props.aspResourcesData.resource.isignum);
             if (signumCheck !== UserAdminHelper.validSignumMessage) {
                 const notification = new Notification({
                     title: 'Isignum not valid',
                     description: signumCheck,
                     timeout: 5000,
                     icon: 'icon-triangle-warning'
                 });
                 notification.init();
             } else*/

            if (!CommonHelper.isValidEmail(this.props.aspResourcesData.resource.email)) {
                const notification = new Notification({
                    title: 'Email not valid',
                    description: 'Please enter a valid Email address.',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else if (!CommonHelper.isValidEmail(this.props.aspResourcesData.resource.lineManagerEmail)) {
                const notification = new Notification({
                    title: 'Email not valid',
                    description: 'Please enter a valid line manager Email address.',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else {
                this.setState({
                    isLoading: true
                });
                const notification = new Notification({
                    title: 'Save Resource',
                    description: 'Resource is being saved.',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                ///adding logs//
                var inputResource = {
                    id: this.props.aspResourcesData.resource.id,
                    name: this.props.aspResourcesData.resource.name,
                    aspCompanyId: this.props.aspResourcesData.resource.aspCompanyId,
                    lineManagerEmail: this.props.aspResourcesData.resource.lineManagerEmail,
                    countryId: this.props.aspResourcesData.resource.countryId,
                    legalStatusId: parseInt(this.props.aspResourcesData.resource.legalStatusId),
                    jobId: this.props.aspResourcesData.resource.jobId,
                    identificationTypeId: this.props.aspResourcesData.resource.identificationTypeId,
                    email: this.props.aspResourcesData.resource.email,
                    phone: this.props.aspResourcesData.resource.phone,
                    location: this.props.aspResourcesData.resource.location,
                    dateJoined: this.props.aspResourcesData.resource.dateJoined,
                    isignum: this.props.aspResourcesData.resource.isignum,
                    identificationNumber: this.props.aspResourcesData.resource.identificationNumber,
                    identificationExpiry: this.props.aspResourcesData.resource.identificationExpiry,
                    remarks: this.props.aspResourcesData.resource.remarks,
                    teamLead: (this.props.aspResourcesData.resource.teamLead == true
                        || this.props.aspResourcesData.resource.teamLead == 1 ? true : false),
                    active: this.props.aspResourcesData.resource.active,
                    status: this.props.aspResourcesData.resource.status,
                    approveDate: this.props.aspResourcesData.resource.approveDate,
                    createDate: this.props.aspResourcesData.resource.createDate,
                    path: (this.state.imagePath.length != 0 ? this.state.imagePath : ""),
                    imageName: (this.state.imageName ? this.state.imageName : ""),
                    positionName: this.props.aspResourcesData.resource.positionName,
                    lastModifiedUser: parseInt(this.props.userData.userData.id),
                    customerId: parseInt(this.props.aspResourcesData.resource.customerId),
                    cvName: (this.state.cvName ?? ""),
                    cvPath: (this.state.cvPath ?? "")
                }
                let visitor = CommonHelper.getVisitorData();
                var logInfo = {
                    userId: parseInt(this.props.userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(inputResource).reduce((a, b) => {
                        return a + b.join(" : ") + " | ";
                    }, ""),
                }
                ApiClient.post("Asp/UpdateResource", {
                    Resource: inputResource, ResourceJobs: list, logInfo: logInfo
                }).then(response => {
                    if (response.data) {
                        var resource = { ... this.props.aspResourcesData.resource };
                        if (response.data.result.error) {
                            const notification = new Notification({
                                title: 'Save Resource Failed',
                                description: response.data.result.result,
                                timeout: 5000,
                                icon: 'icon-cross'
                            });
                            notification.init();
                            this.setState({
                                isLoading: false
                            })
                        }
                        else {
                            if (response.data.result != "done") {
                                resource["path"] = response.data.result.img;
                                resource["cvPath"] = response.data.result.cvLink;
                                this.props.setAspResource(resource);
                            }
                            this.props.createEditResourceForm(false);
                            this.updateData();
                        }
                    }
                }).catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    })
                })
            }
        }
    }
    openUpload = (flag) => {
        if(flag)
        {
            this.inputRef.current.click();
        }
    }
    deleteCV = () => {
        this.setState({
            cvPath: "",
            cvName: "",
        });
    }
    openCVUpload = () => {
        this.cvInputRef.current.click();
    }
    addResource = () => {
        this.props.setSelectedItem(1);
        var _pills = document.getElementsByClassName("pill");
        var pills = [];
        for (let p of _pills) {
            pills.push(p.innerHTML)
        }
        const resource = { ...this.props.aspResourcesData.resource };
        resource["status"] = "Created";
        var newJobs = [];
        if (pills.length == 0 || !pills) {
            resource["jobList"] = [];
            newJobs = [];
        }
        else {
            resource["jobs"] = pills;
            var requiredJobList = this.props.aspResourcesData.jobs.filter(item => pills.includes(item.text));
            var requiredJobIds = requiredJobList.map(item => item.value);
            var jobList = [...resource['jobList']];
            jobList.forEach(e => {
                if (requiredJobIds.includes(e.value) || requiredJobIds.includes(e.jobId)) {
                    if (!e.jobId)
                        e.jobId = e.value;
                    if (newJobs.findIndex(item => item.jobId == e.jobId) == -1)
                        newJobs.push(e);
                }
            })
            resource['jobList'] = newJobs;
        }
        this.props.setAspResource(resource)
        //const resource = { ...this.props.aspResourcesData.resource };
        const list = newJobs;
        const mappedList = list?.map(item => parseInt(item.jobId));
        const teamLeader = (this.props.aspResourcesData.resource.teamLead == true
            || this.props.aspResourcesData.resource.teamLead == 1 ? true : false)
        if (mappedList?.includes(constants.jobsDict.EHS_Auditor) && (mappedList?.length > 1 || teamLeader )) {
            const notification = new Notification({
                title: 'Save Resource',
                description: 'EHS Auditor Cannot be assigned with team leader any other job role.',
                //timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (mappedList?.includes(constants.jobsDict.Driver) && mappedList?.includes(constants.jobsDict.Rigger)) {
            const notification = new Notification({
                title: 'Save Resource',
                description: 'Driver and Rigger Job roles cannot be assigned together, please delete any one job and save again',
                //timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (!this.props.aspResourcesData.resource.name || !this.props.aspResourcesData.resource.email
            || !this.props.aspResourcesData.resource.lineManagerEmail ||
            !this.props.aspResourcesData.resource.identificationTypeId || !this.props.aspResourcesData.resource.identificationNumber ||
            !this.props.aspResourcesData.resource.aspCompanyId ||
            //!this.props.aspResourcesData.resource.isignum ||
            !pills.length
        ) {
            const notification = new Notification({
                title: 'Save Resource',
                description: 'Mandatory fields need to be filled to save.',
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            //toast.error("Fields with star must be filled to save. ", { containerId: '_editResource', position: toast.POSITION.TOP_CENTER });
        }
        else {
            /*
             const signumCheck = UserAdminHelper.isValidResourceSignum(this.props.aspResourcesData.resource.isignum);
            if (signumCheck !== UserAdminHelper.validSignumMessage) {
                const notification = new Notification({
                    title: 'Isignum not valid',
                    description: signumCheck,
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else*/
            if (!CommonHelper.isValidEmail(this.props.aspResourcesData.resource.email)) {
                const notification = new Notification({
                    title: 'Email not valid',
                    description: 'Please enter a valid Email address.',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else if (!CommonHelper.isValidEmail(this.props.aspResourcesData.resource.lineManagerEmail)) {
                const notification = new Notification({
                    title: 'Email not valid',
                    description: 'Please enter a valid line manager Email address.',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else {
                this.setState({
                    isLoading: true
                });
                const notification = new Notification({
                    title: 'Save Resource',
                    description: 'Resource is being created.',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                var inputResource = {
                    name: this.props.aspResourcesData.resource.name,
                    aspCompanyId: this.props.aspResourcesData.resource.aspCompanyId,
                    lineManagerEmail: this.props.aspResourcesData.resource.lineManagerEmail,
                    countryId: this.props.aspResourcesData.resource.countryId,
                    legalStatusId: parseInt(this.props.aspResourcesData.resource.legalStatusId),
                    jobId: this.props.aspResourcesData.resource.jobId,
                    identificationTypeId: this.props.aspResourcesData.resource.identificationTypeId,
                    email: (this.props.aspResourcesData.resource.email || "").toLowerCase(),
                    phone: this.props.aspResourcesData.resource.phone,
                    location: this.props.aspResourcesData.resource.location,
                    dateJoined: this.props.aspResourcesData.resource.dateJoined,
                    isignum: this.props.aspResourcesData.resource.isignum,
                    identificationNumber: this.props.aspResourcesData.resource.identificationNumber,
                    identificationExpiry: this.props.aspResourcesData.resource.identificationExpiry,
                    remarks: this.props.aspResourcesData.resource.remarks,
                    teamLead: (this.props.aspResourcesData.resource.teamLead == true
                        || this.props.aspResourcesData.resource.teamLead == 1 ? true : false),
                    active: this.props.aspResourcesData.resource.active,
                    status: this.props.aspResourcesData.resource.status,
                    approveDate: this.props.aspResourcesData.resource.approveDate,
                    createDate: this.props.aspResourcesData.resource.createDate,
                    path: (this.state.imagePath.length != 0 ? this.state.imagePath : ""),
                    imageName: (this.state.imageName ? this.state.imageName : ""),
                    positionName: this.props.aspResourcesData.resource.positionName,
                    lastModifiedUser: parseInt(this.props.userData.userData.id),
                    customerId: parseInt(this.props.aspResourcesData.resource.customerId),
                    cvPath: this.state.cvPath ?? "",
                    cvName: this.state.cvName ?? ""
                }
                let visitor = CommonHelper.getVisitorData();
                var logInfo = {
                    userId: parseInt(this.props.userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(inputResource).reduce((a, b) => {
                        return a + b.join(" : ") + " , ";
                    }, ""),
                }
                ApiClient.post("ASP/AddResource", { resource: inputResource, ResourceJobs: list, logInfo: logInfo })
                    .then(response => {
                        if (response) {
                            if (response.data.result.error) {
                                const notification = new Notification({
                                    title: 'Save Resource Failed',
                                    description: response.data.result.result,
                                    timeout: 5000,
                                    icon: 'icon-cross'
                                });
                                notification.init();
                            }
                            else {
                                if (response.data.result.result === "Exist") {
                                    const notification = new Notification({
                                        title: 'Create Resource Error',
                                        description: 'Resource with same Email address already exist',
                                        timeout: 5000,
                                        icon: 'icon-cross'
                                    });
                                    notification.init();
                                }
                                else {
                                    resource["id"] = response.data.result.id;
                                    resource["path"] = response.data.result.img;
                                    resource["certs"] = [];
                                    resource["inCompletedCerts"] = AspHelper.getUnTakenCerts([], this.props.aspResourcesData.jobs, [], resource["jobs"]);
                                    resource["skills"] = [];
                                    resource["cvPath"] = response.data.result.cvLink
                                    this.updateData();
                                    this.props.changeResourceMod(constants.pageMods.VIEW_ONLY);
                                    this.props.createEditResourceForm(false);
                                }
                            }
                            this.setState({ isLoading: false });
                        }
                    }).catch(error => {
                        console.warn(error.response.data)
                        alert(error);
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        }
    }
    checkToAdd = () => {
        if (this.state.imagePath.length || this.props.aspResourcesData.resource.path || this.props.aspResourcesData.resource.profileImg) {
            if (this.props.aspResourcesData.createResource)
                this.addResource();
            else
                this.SaveChanges();
        }
    }
    isEmailEditingDisabled = () => {
        if (!this.props.aspResourcesData.createResource) {
            if (this.props.userData.userData.jobRole !== constants.userRoles.VALIDATION_OFFICER) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    render() {
        return (
            <EuiRow >
                <EuiRow className={this.props.aspResourcesData.disabledResource ? "disabled" : ""}>
                    <div className="column xl-6 lg-6 sm-12">
                        <EuiTile fullwdith >
                            <EuiRow disabled={this.props.isLoading}>
                                <div className={"column xl-4 lg-4 m-12 sm-12 " + (this.state.disableSave ? "hidden" : "")}>
                                </div>
                                <div className="column xl-8 lg-8 m-12 sm-12">
                                    <FormField className="flex-end-content">
                                        <EuiCheckbox
                                            label="Team Leader"
                                            defaultChecked={false}
                                            name="teamLead"
                                            id="teamLead"
                                            refValue={this.props.aspResourcesData.resource.teamLead}
                                            onChange={(e) => this.handleChange(e)}
                                        />
                                    </FormField>
                                </div>
                            </EuiRow>
                            <EuiRow>
                                <div className="column xl-4 lg-12 m-12 sm-12">
                                    <label className="flex-start" style={{ color: "gray" }}>
                                        <span className="w-8">Click on the image to change it.</span>
                                        <span className="tooltip" style={{ marginLeft: "30px" }} id="rmvImg" clickable onClick={this.removeImg}><i class="icon icon-cross" ></i>
                                            <span className="message right">Remove Image</span>
                                        </span>
                                    </label>
                                    <FormField className="w-6 mb-2" >
                                        {(this.state.imgPreview || this.props.aspResourcesData.resource.path
                                            || this.props.aspResourcesData.resource.profileImg ?
                                            <label htmlFor="imgInput" style={{ height: "200px", width: "200px" }} >
                                                <img src={(this.state.imgPreview ? this.state.imgPreview :
                                                    (this.props.aspResourcesData.resource.faceId ?
                                                        (this.props.aspResourcesData.resource.path ?
                                                            SasHelper.addSasKey(this.props.aspResourcesData.resource.path, this.props.sasToken) :
                                                            SasHelper.addSasKey(this.props.aspResourcesData.resource.profileImg, this.props.sasToken))
                                                        : ""))}
                                                    style={{ height: "100%", width: "100%" }}
                                                    type="file"
                                                    onClick={this.openUpload}
                                                />
                                            </label>
                                            : <div onClick={() => this.openUpload(true)} clickable
                                                style={{ backgroundColor: "#878787", height: "200px", width: "200px" }}>
                                                <i className="icon icon-camera-photo"
                                                    style={{ textAlign: "center", fontSize: "35px", verticalAlign: "middle" }} />
                                            </div>)}
                                        <input style={{ marginRight: "0px", display: "none" }} id="imgInput"
                                            label="Upload Profile Picture"
                                            ref={this.inputRef}
                                            type="file" name="crt"
                                            labelstyle={{ fontSize: "15px" }}
                                            onChange={(e) => this.imageChange(e)}
                                            accept=".jpg,.jpeg,.png"
                                        >
                                        </input>
                                    </FormField>
                                </div>
                                <div className="column xl-8 lg-12  m-12 sm-12">
                                    <FormField className="w-6 mt-2 mb-2">
                                        <EuiTextField required className="w-10" fullwidth id="filtername" name="name" label="Employee Name" labelClass="required" labelStyle={{ fontSize: "14px" }}
                                            value={(this.props.aspResourcesData.resource.name)} onChange={(e) => this.handleChange(e)} />
                                    </FormField>
                                    <FormField className="w-6 mt-2 mb-2">
                                        <EuiTextField disabled={this.isEmailEditingDisabled()} required type="email" className="w-10" fullwidth id="email" name="email" label="Email Address" labelClass="required" labelStyle={{ fontSize: "14px" }}
                                            value={this.props.aspResourcesData.resource.email} onChange={(e) => this.handleChange(e)} />
                                    </FormField>
                                    <FormField className="w-6 mt-2 mb-2">
                                        <EuiTextField className="w-10" fullwidth id="phone" name="phone" label="Mobile Number" type="tel" labelStyle={{ fontSize: "14px" }}
                                            value={(this.props.aspResourcesData.resource.phone)} //pattern="/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
                                            //placeholder="XXX-XXX-XXXX"
                                            onChange={(e) => this.handleChange(e)} />
                                    </FormField>
                                </div>
                            </EuiRow>
                            <label style={{ color: "gray" }}>
                                * Please upload picture with clear facial features as this image will be used for face verification
                            </label>
                            <EuiRow>
                                <div className="column xl-12 lg-12 m-12 sm-12">
                                    <FormField className="w-10 mb-1" key={this.props.userData.countries}>
                                        <label style={{ fontSize: "14px" }} htmlFor="countrySelect">Country</label>
                                        <EuiAutocompleteSelect id="nationalitySelect" name="companyCountry" label="---Select Country---"
                                            className="w-10"
                                            fullwdith={true}
                                            style={{ width: "100%", maxWidth: "100%" }}
                                            options={this.state.countries}
                                            value={this.props.aspResourcesData.resource.companyCountry}
                                            onChange={(e) => this.handleChange(e)}
                                            optionListStyle={{ width: "100%" }}
                                        />
                                    </FormField>
                                    <EuiRow key={this.props.userData.customers}>
                                        <div className="column xl-6 lg-6 sm-12">
                                            <FormField className="w-10 mb-1" >
                                                <label className="required" style={{ fontSize: "14px" }} htmlFor="companySelect">Company Name </label>
                                                <EuiAutocompleteSelect
                                                    key={this.state.aspCompanies}
                                                    required
                                                    className="w-10"
                                                    label="---Select Company---"
                                                    id="companySelect"
                                                    style={{ width: "100%", maxWidth: "100%" }}
                                                    optionListStyle={{ width: "100%" }}
                                                    name="aspCompanyId"
                                                    options={this.state.aspCompanies}
                                                    value={this.props.aspResourcesData.resource.aspCompanyId || 0}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="column xl-6 lg-6 sm-12">
                                            <FormField className="w-10 mb-1" >
                                                <label className="required" style={{ fontSize: "14px" }} htmlFor="customerSelect">Customer Name </label>
                                                <EuiAutocompleteSelect
                                                    key={this.state.customers}
                                                    required
                                                    className="w-10"
                                                    label="---Select Customer---"
                                                    id="customerSelect"
                                                    style={{ width: "100%", maxWidth: "100%" }}
                                                    optionListStyle={{ width: "100%" }}
                                                    name="customerId"
                                                    options={this.state.customers}
                                                    value={this.props.aspResourcesData.resource.customerId || 0}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </FormField>
                                        </div>
                                    </EuiRow>
                                    <EuiRow>
                                        <div className="column xl-6 lg-6 sm-12">
                                            <FormField className="w-10  mb-1">
                                                <EuiTextField className="w-10" fullwidth id="isignum" name="isignum" label="Employee iSignum"
                                                    value={this.props.aspResourcesData.resource.isignum} onChange={(e) => this.handleChange(e)} />
                                            </FormField>
                                        </div>
                                        <div className="column xl-6 lg-6 sm-12">
                                            <FormField className="w-10  mb-1">
                                                <EuiTextField className="w-10" fullwidth id="positionName" name="positionName" label="Position"
                                                    value={this.props.aspResourcesData.resource.positionName} onChange={(e) => this.handleChange(e)} />
                                            </FormField>
                                        </div>
                                    </EuiRow>
                                    <FormField className="w-5 mb-1">
                                        <EuiTextField required className="w-10" fullwidth id="lineManagerEmail" name="lineManagerEmail" labelClass="required" label="ASP Manager Email Address" labelStyle={{ fontSize: "14px" }}
                                            value={this.props.aspResourcesData.resource.lineManagerEmail} onChange={(e) => this.handleChange(e)} />
                                    </FormField>
                                </div>
                            </EuiRow>
                        </EuiTile>
                    </div>
                    <div className="column xl-6 lg-6 sm-12">
                        <EuiRow >
                            <div className="column xl-6 lg-6 sm-12">
                                <EuiTile tileStyle={{ height: "324px" }} >
                                    <FormField className="w-10 mt-2 mb-2" style={{ height: "275px", overflowY: "scroll" }} >
                                        <label className="required" style={{ fontSize: "14px" }} htmlFor="jobSelect">Job Roles</label>
                                        <label style={{ fontSize: "12px", color: "gray" }} htmlFor="jobSelect">Add at least one Job Role. </label>
                                        <EuiMultiSelect style={{ width: "100%" }}
                                            required
                                            name="jobs"
                                            id="jobRole"
                                            options={this.props.aspResourcesData.jobs}
                                            value={this.props.aspResourcesData.resource.jobs}
                                            onChange={(e) => this.handleChange(e)}
                                            optionListStyle={{ width: "100%" }}
                                            styleDiv={{ width: "100%" }}
                                        />
                                        <div style={{ marginTop: "10px" }}>
                                            <label style={{ fontSize: "14px" }} >Upload CV <span style={{ fontSize: "12px", color: "gray", display: "none" }}>.pdf and .doc format only</span> </label>
                                            {(this.state.cvPath?.length > 0) ?
                                                <>
                                                    <input readOnly={true} id="cvNameId" style={{ fontWeight: "bold" }} value={this.state.cvName} />
                                                    <span className="tooltip"><i style={{ color: "red", fontSize: "15px", fontWeight: "bold" }} className="icon icon-cross clickable" onClick={() => this.deleteCV()}></i><span className="message top-end"> Delete CV </span></span>
                                                </>
                                                :
                                                <><div onClick={this.openCVUpload} className="clickable m-2"
                                                    style={{ height: "50px", width: "50px" }}>

                                                    <i clickable className="icon icon-upload clickable"
                                                        style={{ alignItems: "center", fontSize: "25px", fontWeight: "bold" }} />
                                                </div>
                                                    <input style={{ marginRight: "0px", display: "none" }} id="cvInput"
                                                        label="Upload CV"
                                                        ref={this.cvInputRef}
                                                        type="file"
                                                        name="resourceCV"
                                                        onChange={(e) => this.onCVChange(e)}
                                                        accept=".doc,.pdf"
                                                    >
                                                    </input>
                                                </>
                                            }</div>
                                    </FormField>
                                </EuiTile>
                            </div>
                            <div className="column xl-6 lg-6 sm-12">
                                <EuiTile tileStyle={{ height: "324px" }}>
                                    <FormField className="w-6 mt-2 mb-2">
                                        <label className="required" style={{ fontSize: "14px" }} htmlFor="Identification Type">Identification Type</label>
                                        <EuiSelect id="idType" name="identificationTypeId" label="---Select ID Type---"
                                            // styleDiv={{ width: "90%" }}
                                            options={this.props.aspResourcesData.idTypes}
                                            value={this.props.aspResourcesData.resource.identificationTypeId}
                                            onChange={(e) => this.handleChange(e)}
                                            optionListStyle={{ width: "100%" }}
                                        />
                                    </FormField>
                                    <FormField className="w-6 mt-2 mb-2">
                                        <label style={{ fontSize: "14px" }} htmlFor="countrySelect">Nationality</label>
                                        <EuiAutocompleteSelect id="countrySelect" name="countryId" label="---Select Nationality---"
                                            style={{ width: "100%" }}
                                            options={this.props.userData.countries}
                                            value={this.props.aspResourcesData.resource.countryId}
                                            onChange={(e) => this.handleChange(e)}
                                            optionListStyle={{ width: "100%" }}
                                        />
                                    </FormField>
                                    <FormField className="w-6 mt-2 mb-2">
                                        <EuiTextField style={{ width: "100%" }} type="numbers" required fullwdith className="w-10" id="idNumber" name="identificationNumber" labelClass="required" label="Identification Number" value={(this.props.aspResourcesData.resource.identificationNumber)}
                                            onChange={(e) => this.handleChange(e)} />
                                    </FormField>
                                    <FormField className="w-6 mt-2 mb-2">
                                        <EuiDatepicker
                                            style={{ width: "100%" }}
                                            className="w-10"
                                            id="expiredate"
                                            label="Identification Expiry Date"
                                            dateStyle={{ marginRight: 6 }}
                                            // placeholder="YYYY-MM-DD"
                                            name="identificationExpiry"
                                            value={this.props.aspResourcesData.resource.identificationExpiry}
                                            onChange={this.handleChange}
                                            labelStyle={{ fontSize: "14px" }}
                                        />
                                    </FormField>
                                </EuiTile>
                            </div>
                        </EuiRow>
                        <EuiRow>
                            <EuiTile fullwdith
                                tileStyle={{ height: "100%" }}
                            >
                                <div >
                                    <EuiRow >
                                        <div className="column xl-12 lg-12 m-12 sm-12">
                                            <FormField className="w-3  mb-1">
                                                <label className="required" labelClass="w-10" style={{ fontSize: "14px" }} >Status</label>
                                                {(this.props.aspResourcesData.resource.status == "Blacklisted" ?
                                                    <label labelClass="w-10" style={{ fontSize: "12px", color: 'red' }} >Blacklisted resources can't be edited.</label>
                                                    : null)}
                                                <EuiSelect id="status" name="status" label="---Select Status ---"
                                                    options={this.props.aspResourcesData.statuses}
                                                    value={(this.props.aspResourcesData.createResource ? 1 :
                                                        this.props.aspResourcesData.statuses.find(item => item.text == this.props.aspResourcesData.resource.status)?.value)}
                                                    onChange={(e) => this.handleChange(e)}
                                                    optionListStyle={{ width: "100%" }}
                                                    disabled={this.props.aspResourcesData.createResource || this.props.userData.userData.jobRole === constants.userRoles.ASP_PROJECT_MANAGER}
                                                />
                                            </FormField>
                                            <div className="column xl-12 lg-12 sm-12">
                                                <FormField className="w-10">
                                                    <EuiTextArea className="w-10" fullwidth id="remarks" name="remarks" label="Remarks" style={{ height: "124px" }}
                                                        value={this.props.aspResourcesData.resource.remarks} onChange={(e) => this.handleChange(e)} />
                                                </FormField>
                                            </div>
                                        </div>
                                    </EuiRow>
                                </div>
                            </EuiTile>
                        </EuiRow>
                    </div>
                </EuiRow >
                <EuiRow>
                    <div className="column sm-12 md-12 lg-12 xl-12 filled" >
                        <div className="wizard-footer">
                            <div className="item" style={{ marginLeft: "20px" }}>
                                <button className="btn" onClick={this.Cancel}>Cancel</button>
                            </div>
                            <div className="item" style={{ marginRight: "20px" }}>
                                <button hidden={this.props.aspResourcesData.disabledResource} className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" :
                                    (this.state.disableSave ? "disabled" : ""))} id="saveBtn"
                                    onClick={this.checkToAdd}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </EuiRow>
                {
                    ((!this.state.imagePath.length && !this.props.aspResourcesData.resource.path && !this.props.aspResourcesData.resource.profileImg) ?
                        < EuiDialog
                            id="addResourceDialog"
                            dataTrigger="#saveBtn"
                            title="No image uploadded"
                            body={<div><p>This will prevent photo authentication.</p><b />
                                <p>Are you sure you want to continue?</p></div>}
                            buttonName="Continue"
                            type="primary"
                            onCancel={this.onCancel}
                            onClick={(this.props.aspResourcesData.createResource ? this.addResource : this.SaveChanges)}
                            done={this.state.Done}
                        />
                        : null)
                }
            </EuiRow >
        );
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sessionData: state.session, userAdmin: state.userAdmin, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        createEditResourceForm: (state) => dispatch({ type: "CREATE_EDIT_RESOURCE_FORM", payload: state }),
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        setCreateResource: (state) => dispatch({ type: "SET_CREATE_RESOURCE", payload: state }),
        changeResourceMod: (mod) => dispatch({ type: "CHANGE_RESOURCE_MOD", payload: mod }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditResource);
