import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EuiRow from "../../../eui-components/row";
import { ToastContainer, toast } from "react-toastify";
import LiveStream from "./liveStream";
import Table from "../../../components/table";
import constants from "../../../utils/constants";
import { Tooltip, IconButton } from "@material-ui/core";
import EuiTabs from "../../../eui-components/tabs";
import PageHelper from "../../../utils/page-helper";
import DateUtils from "../../../utils/date-utils";
import ApiClient from "../../../utils/api-client";
import EuiTile from "../../../eui-components/tile";
import Cookies from "js-cookie";

class LiveStreaming extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            titles: [{ value: 1, name: "", selected: "selected" },
                // { value: 2, name: "Remote EHS Inspection", selected: "" },
                // { value: 3, name: "Remote Quality Audit or Site Supervision", selected: "" },
                // { value: 4, name: "VSS/VCOP", selected: "" }
            ],
            sessionColumns:
                [
                    {
                        title: "Session ID",
                        field: "id",
                        width: "5%",
                        defaultSort: "desc"
                    },
                    {
                        title: "Session Name",
                        field: "name",
                        width: "15%",
                    },
                    {
                        title: "Customer Name",
                        field: "customerName",
                        hidden: this.props.userData.loginRole === 1 ? true : false,
                    },
                    {
                        title: "Customer Project Name",
                        field: "customerProjectName",
                        hidden: this.props.userData.loginRole === 1 ? false : true,
                    },
                    {
                        title: "Customer Scope Identification",
                        field: "customerScopeIdentification",
                        hidden: this.props.userData.loginRole === 1 ? false : true,
                    },
                    {
                        title: "Site Code",
                        field: "siteCode",
                    },
                    {
                        title: "Session Status", field: "status",
                        render: (rowData) => PageHelper.renderSessionStatus(rowData.status),
                        lookup: constants.statusLookup,
                    },
                    {
                        title: "Submited Date", field: "submitDate",
                        render: (rowData) => DateUtils.toDateString(rowData.submitDate, "YYYY-MM-DD HH:mm"),
                    },
                    {
                        title: "Start DateTime",
                        render: (rowData) => DateUtils.toDateString(rowData.liveStreamStartDate, "YYYY-MM-DD") + " " + rowData.liveStreamStartTime
                    },
                    {
                        title: "End DateTime",
                        render: (rowData) => DateUtils.toDateString(rowData.liveStreamEndDate, "YYYY-MM-DD") + " " + rowData.liveStreamEndTime,
                    },
                    {
                        title: "Action",
                        render: (rowData) => this.renderSessionActions(rowData),
                    },
                ],
            liveSessions: [],
            currentRow: { id: 0 },
            supervisionData: [],
            vssData: [],
            ehsData: [],
        };
    }
    componentDidMount() {
        this.getAllLiveSession();

    }

    getAllLiveSession = () => {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }

        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/GetAllLiveSession", { id: parseInt(this.props.userData.userData.userId) })
                .then(response => {
                    if (!response.data) {
                        toast.error("Some errors occured, Please try later!", { containerId: 'LS', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                    const liveSessions = [...response.data];
                    this.mapLiveSessions(liveSessions);
                    
                    this.setState({ liveSessions, isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    mapLiveSessions = (liveSessions) => {
        if (!liveSessions) {
            return;
        }
        liveSessions.map(item => {
            if (item.sessionStreamings && item.sessionStreamings.length > 0) {
                item.sessionStreamings.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                const liveSessionStatus = item.sessionStreamings[0].status;
                const liveSessionInfo = {
                    type: constants.systemTypes.SESSION,
                    sessionCode: item.sessionStreamings[0].sessionCode,
                    latitude: item.sessionStreamings[0].latitude,
                    longitude: item.sessionStreamings[0].longitude,
                    streamingUserName: item.sessionStreamings[0].streamingUserName + constants.streamerSuffix,
                    streamingUserId: item.sessionStreamings[0].streamingUserId,
                    streamingId: item.sessionStreamings[0].id,
                    sessionId: item.id,
                    sessionName: item.name,
                    siteName: item.siteCode,
                    activityId : item.id,
                };
                
                item["liveSessionStatus"] = liveSessionStatus;
                item["liveSessionInfo"] = liveSessionInfo;
            } else {
                item["liveSessionStatus"] = 0;
            }
        });
        
    }

    renderSessionActions = (rowData) => {
        return <div style={{ display: "flex", alignItems: "center" }}>
            <button className={"btn " + (rowData.liveSessionStatus === 1 ? "primary" : "secondary")} onClick={() => this.joinSession(rowData)}>Join</button>
            {rowData.liveSessionStatus !== 1 ?
                <Tooltip title="Refresh Session">
                    <IconButton onClick={() => this.refreshSession(rowData)}>
                        <i className="icon icon-reload" style={{ margin: 5, fontSize: 18 }}></i>
                    </IconButton>
                </Tooltip>
                : null
            }
        </div>
    }

    refreshSession = (rowData) => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/GetLiveSession", rowData)
                .then(response => {
                    if (!response.data) {
                        toast.error("Some errors occured, Please try later!", { containerId: 'LS', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                    
                    const liveSession = [response.data];
                    this.mapLiveSessions(liveSession);
                    
                    const liveSessions = [...this.state.liveSessions];
                    liveSessions[liveSessions.indexOf(rowData)] = liveSession[0];
                    this.setState({ liveSessions, isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    joinSession = (rowData) => {
        this.refreshSession(rowData);
        Cookies.remove('vehicleStatusIsGreen')

        if (rowData.liveSessionStatus !== 1) {
            toast.error("Live Session has not yet been started.", { containerId: 'LS', position: toast.POSITION.TOP_CENTER });
            return;
        }

        const liveSessionInfo = { ...rowData.liveSessionInfo };
        //Between Rsc and customer live session type
        liveSessionInfo.type = constants.systemTypes.SESSION;
        if (this.props.userData.loginRole === 1) {
            this.acceptSession(rowData);
        } else {
            this.viewOnlySession(rowData);
        }
        this.props.setLiveSessionInfo(liveSessionInfo);
        this.props.toggleLiveStreamMod(true);
    }

    acceptSession = (rowData) => {
        this.props.setApproveSession({ id: rowData.id, name: rowData.name, siteCode: rowData.siteCode, status: 2, customerUsers: rowData.customerUsers, responsibleUsers: rowData.responsibleUsers });
    }

    viewOnlySession = (rowData) => {
        const sessionRequest = { id: rowData.id, name: rowData.name, siteCode: rowData.siteCode, viewOnly: true, status: 2, customerUsers: rowData.customerUsers, responsibleUsers: rowData.responsibleUsers };
        this.props.setApproveSession(sessionRequest);
    }

    renderMod = () => {
        const contents = [{
            value: 1, selected: "selected", children: <Table
                columns={this.state.sessionColumns}
                data={this.state.liveSessions}
                isLoading={this.state.isLoading}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10],
                    cellStyle: { fontSize: "0.875rem", padding: 0, paddingLeft: 10 },
                    paginationType: "stepped",
                }}
                onRowClick={(event, rowData) => this.setState({ currentRow: rowData })}
            >
            </Table>
        },
        ];

        if (!this.props.liveStreamData.mod) {
            return (
                <EuiRow>
                    <EuiTabs
                        id="liveSessionsTab"
                        tabStyle={{ width: "100%" }}
                        titles={this.state.titles}
                        contents={contents}
                    >
                    </EuiTabs>
                </EuiRow>
            );
        } else {
            return (<EuiRow>
                <LiveStream getAllLiveSession={this.getAllLiveSession} />
            </EuiRow>);
        }
    }

    render() {
        return (
            <>
                {<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}>{this.renderMod()}</EuiTile>}
                <ToastContainer enableMultiContainer containerId={'LS'} autoClose={2500} />
            </>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, liveStreamData: state.liveStream, sessionData: state.session });

const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod  }),
        setApproveSession: approveSession => dispatch({ type: "SET_APPROVE_SESSION", payload: approveSession }),
        setLiveSessionInfo: liveSessionInfo => dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(LiveStreaming)));
