
import PageHelper from "./page-helper";

var target = process.env.ASPNETCORE_HTTPS_PORT ? `https://localhost:${process.env.ASPNETCORE_HTTPS_PORT}` :
    process.env.ASPNETCORE_URLS ? process.env.ASPNETCORE_URLS.split(';')[0] : 'https://localhost:44375';
var localTarget = 'https://localhost:44375';
var stagingTarget = 'https://staging.remote.acceptance.customerprojects.ericsson.net';
var prodTarget = 'https://remote-acceptance-customerprojects.ericsson.net';
var uatTarget = 'https://uat.remote.acceptance.customerprojects.ericsson.net'
//target =  localTarget
//target =  stagingTarget
 //target =  prodTarget
target =   uatTarget
//target =  override me please to switch between prod , staging , uat etc
var mobileBaseURL = target;  // change value here i.e run on same server . If you wanna switch between staging ,  uat , etc . By default this is applicable for dev and deployed build

var mobileServerWithOutSSO = 'https://eritopaspmobile.azurewebsites.net';

export default {
    //baseUrl: process.env.NODE_ENV === "production" ? "https://remote-acceptance-customerprojects.ericsson.net/api/" : "http://localhost:59258/api/",
    // baseUrl: process.env.NODE_ENV === "production" ? "https://eritop-asppm.azurewebsites.net/api/" : "http://localhost:59258/api/",
    // azureAdRedirectURL: 'https://staging.remote.acceptance.customerprojects.ericsson.net',
 //  azureAdRedirectURL: 'https://remote-acceptance-customerprojects.ericsson.net',
   
    //azureAdRedirectURL: 'https://localhost:44375',
  //  azureAdRedirectURL: 'http://localhost:3000',
   
    azureAdRedirectURL: "http://localhost:3000",
    baseUrl: `${target}/api/`,
    mobilUrl: `${mobileBaseURL}/mobile/api/`,
    //mobilChatUrl: process.env.NODE_ENV === "production" ? "https://eritopaspmobile.azurewebsites.net/ticketingchat" : "https://eritopaspmobile-dev.azurewebsites.net/ticketingchat",
    //  mobilChatUrl: process.env.NODE_ENV === "production" ? "https://eritopaspmobile-dev.azurewebsites.net/ticketingchat" : "https://eritopaspmobile-dev.azurewebsites.net/ticketingchat",
    mobilChatUrl: `${mobileBaseURL}/mobile/ticketingchat`, // with SSO . need to use this once mobile is moved to sso and deployed with SSO server
    ChatUrlMobil :`${mobileBaseURL}/mobile/realchathub`, // with SSO . need to use this once mobile is moved to sso and deployed with SSO server
  //  mobilChatUrl : `${mobileServerWithOutSSO}/ticketingchat`,
   // ChatUrlMobil :`${mobileServerWithOutSSO}/realchathub`,
    testURL: `${stagingTarget}/api/`,
    localURL: `${localTarget}/api/`,
    prodURL: `${prodTarget}/api/`,
    timeout: process.env.NODE_ENV === "production" ? 70000 : 999999999,
    account: "eritopasp1",
    cdnAccount: "eritop.azureedge",
    storageLink: ".blob.core.windows.net",
    containerName: "session-docs",
    ticketFilesContainer: "ticket-files",
    rscScopeName: "Remote Customer Acceptance",
    rscScopeEhsName: "Remote OHS Inspection",
    rscScopeQA : "Remote Quality Audit – VQA",
    approvalConditions: [{ value: 0, text: "Accepted" },
    { value: 3, text: "Accepted without OIL" },
    { value: 4, text: "Accepted with OIL" },
    { value: 5, text: "Accepted Conditionally" }],
    statusLookup: {
        2: "Created",
        3: "Submitted",
        4: "Rejected",
        5: "Approved",
        7: "Ready For Submisson",
        8: "Accepted Without OIL",
        9: "Accepted with OIL",
        10: "Accepted Conditionally",
        11: "First Approval",
        12: "Second Approval",
        13: "Third Approval",
        14: "Forth Approval",
        15: "Fifth Approval",
        100: "Submitted to ECA",
        101: "Rejected by ECA",
        102: "Approved by ECA"
    },
    job_titles: [{ text: "Admin", value: 0 }, { text: "Ericsson IM", value: 1 },
    { text: "ASP Project Manager", value: 2 }, { text: "Team Member", value: 3 },
    { text: "Team Leader", value: 4 }, { text: "RSC Coordinator", value: 5 },
    { text: "RSC QA Supervisor", value: 6 }, { text: "RSC EHS Inspector", value: 7 },// RSC EHS Supervisor 
    { text: "Ericsson ASP Mentor", value: 8 }, { text: "Ericsson ASP Mentor", value: 8 },
    { text: "ASP Manager", value: 10 },
    { text: "EHS SME", value: 11 },
    { text: "Validation Officer", value: 12 }],
    statusList: [{ value: 1, text: "Pending" }, { value: 2, text: "Accepted" }, { value: 3, text: "Rejected" }, { value: 4, text: "Rework" }],
    loginRoles: [{ value: 1, text: "Customer" }, { value: 2, text: "Ericsson" }, { value: 3, text: "Approved Service Provider" }, { value: 4, text: "Readonly" }],
    loginRolesLookup: { "CUSTOMER": 1, "ERICSSON": 2, "APPROVED_SERVICE_PROVIDER": 3, "REPORTER": 4 },
    workflowStatus: { 1: "Draft", 2: "Released", 3: "Revision in Progress", 4: "Retired" },
    workflowNotification: " Workflow id has been changed and under revision, Please contact session responsible users!",
    severityLevels: [{ value: 1, text: "Ok", rate: 0 }, { value: 2, text: "Critical", rate: 1 }, { value: 3, text: "Major", rate: 0.5 }, { value: 4, text: "Minor", rate: 0.1 }, { value: 5, text: "Observation", rate: 0 }],
    severityLevelsLookup: {
        "OK": 1,
        "CRITICAL": 2,
        "MAJOR": 3,
        "MINOR": 4,
        "OBSERVATION": 5
    },
    templateSeverityLevels: [{ value: 1, text: "Critical" }, { value: 2, text: "Major" }, { value: 3, text: "Minor" }, { value: 4, text: "Observation" }],
    liveStreamRecordCodes: {
        "SESSION_ALREADY_RECORDING": "Recording was already started by another participant, can't start again!",
        "NO_CONNECTED_PARTICIPANTS": "There is no steaming user, can't record video!",
        "ALREADY_RECORDED": "Session was already recorded, can't record again!"
    },
    streamerSuffix: " (Streamer)",
    liveStreamLookup: { 0: "Not Live Stream", 1: "Live Stream" },
    assignedLookup: { 0: "Not Assigned", 1: "Assigned" },
    documentTypes: { "IMAGE": 1, "VIDEO": 2, "PDF": 3, "OTHER_DOCUMENTS": 4, "FILE": 5, "WORD": 6, "EXCEL": 7, "PRESENTATION": 8 },
    activityInboxApprovalTypes: { "SAVE_AND_FINISH_LATER": 1, "APPROVE": 2, "REJECT": 3 },

    errorMessage: "Some issues occured. Please try later!",
    processNegativeMessage: "Your transaction cannot be processed, please repeat it later.",
    processPozitiveMessage: "Your transaction has been completed successfully.",
    processWarnMessage: "Can not approve or reject due to status is pending or rework!",
    acceptanceStatusTypes: { "PENDING": 1, "ACCEPTED": 2, "REJECTED": 3, "REWORK": 4 },
    ratingStatusTypes: { 1: "star", 2: "radio", 3: "free" },
    ericssonEmployeeRoles: [0, 1, 5, 6, 7, 8, 10, 11, 12,33],
    customerRoles: [100],
    aspRoles: [2, 3, 4 , 33],
    readOnlyRoles: [9],
    userRoles: {
        "ADMIN": "0",
        "ERICSSON_IM": "1",
        "ASP_PROJECT_MANAGER": "2",
        "ASP_TEAM_LEADER": "3",
        "TEAM_MEMBER": "4",
        "RSC_COODINATOR": "5",
        "RSC_SITE_SUPERVISOR": "6",
        "RSC_OHS_INSPECTOR": "7",
        "IMPLEMENTATION_MANAGER_ASP_MENTOR": "8",
        "REPORTER": "9",
        "ASP_MANAGER": "10",
        "EHS_SME": "11",
        "VALIDATION_OFFICER": "12",
        "CUSTOMER": "100",
        "EHS_Auditor" : "33"
    },
    cmsDocumentTypes: [{ value: 1, text: "Site Survey Report" },
    { value: 2, text: "Floor Plan" },
    { value: 3, text: "Low Level Design" },
    { value: 4, text: "High Level Design" },
    { value: 5, text: "Method of Procedure" },
    { value: 6, text: "Integration Report" }],
    systemTypes: { "SESSION": 1, "ACTIVITY": 2, "EHS": 3, "VSS": 4 },
    rscRoles: ["5", "6", "7"],
    allJobRole: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "100"],
    vehicle_category: [{ value: 1, text: "LIGHTS & REFLECTORS" }, { value: 2, text: "IN-CAB INSPECTION" }, { value: 3, text: "WHEELS" }, { value: 4, text: "ENGINE" }],
    vehicleStatuses: { "PENDING": 2, "ACCEPTED": 3, "REJECTED": 4 },
    userRolesLookup: {
        0: "Administrator",
        1: "Implementation Management",
        2: "ASP Project Manager",
        3: "ASP Team Leader",
        4: "ASP Team Member",
        5: "RSC Manager",
        6: "RSC Quality Supervisor",
        7: "RSC EHS Inspector",// RSC EHS Supervisor 
        8: "Ericsson ASP Mentor",
        9: "Readonly",
        10: "ASP Manager",
        11: "EHS SME",
        12: "Validation Officer",
        100: "Customer",
        "EHS_Auditor" : "33"
    },
    interfaceTypes: { "NONE": 0, "MOBILE": 1, "WEB": 2 },
    ehsRatingOptions: { "PASS": 4, "FAIL": 2, "NA": 0 },
    ehsRatingOptionsLookup: { 4: "PASS", 2: "FAIL", 0: "NA" },
    ehsRatingLimit: 70,
    pageMods: { "LIST": 1, "EDIT": 2, "VIEW_ONLY": 3, "ADD": 4, "SUB_LIST": 5, "RESOLVE": 6, "APPROVE": 7 },
    activityListTypes: { "WORKPLAN": 1, "ACTIVITY": 2, "WORKPLAN_ACTIVITY": 3, "EHS_PING": 4 },
    documentsView: { "LIST": 1, "GRID": 2 },
    wpTypeFarend: "TX Link",
    profileStorage: "https://eritopasp1.blob.core.windows.net/profile-images/",
    jobs: [
        { value: 1, text: "Engineer" },
        { value: 2, text: "Technician" },
        { value: 3, text: "Rigger" },
        { value: 4, text: "supervisor" },
        { value: 5, text: "ASP - Approved Service Provider" },
        { value: 6, text: "Regional Author" },
        { value: 7, text: "Release Data" },
        { value: 8, text: "Regional System Admins" },
        { value: 9, text: "ASP Manager" },
        { value: 10, text: "Line Manager" },
        { value: 11, text: "Global System Admins" },
        { value: 12, text: "Team Leader" },
        { value: 13, text: "Driver" },],
    acceptanceStatusLookup: { 1: "Pending", 2: "Accepted", 3: "Rejected", 4: "Rework" },
    zipFileLimitSize: 250,
    actionMods: { "CREATE": 1, "EDIT": 2, "VIEW_ONLY": 3, "RESOLVE": 4 },
    erisiteLogName: "Erisite Update",
    logTypes: {
        "NONE": 0,
        "ACTIVITY": 1,
        "OHS": 2,
        "SITE": 4,
        "RESOURCE_CERTIFICATE": 5,
        "RESOURCE": 6
    },
   // interfaceTypes: { "NONE": 0, "MOBILE": 1, "WEB": 3, },
    erisiteStatuses: [{ value: 1, text: "Cancelled", actualUpdate: false },
    { value: 2, text: "Completed", actualUpdate: true },
    { value: 3, text: "Completed-Pending Approval", actualUpdate: false },
    { value: 4, text: "Created", actualUpdate: false },
    { value: 5, text: "Hold", actualUpdate: false },
    { value: 6, text: "Not Applicable", actualUpdate: false },
    { value: 7, text: "Pending Predecessors", actualUpdate: false },
    { value: 8, text: "Ready to Start", actualUpdate: true },
    { value: 9, text: "Retired", actualUpdate: false },
    { value: 10, text: "Review In Progress", actualUpdate: false },
    { value: 11, text: "Started", actualUpdate: true },
    ],
    erisiteStatusesLookup: {
        "CANCELLED": "Cancelled",
        "COMPLETED": "Completed",
        "COMPLETED_PENDING_APPROVAL": "Completed-Pending Approval",
        "CREATED": "Created",
        "HOLD": "Hold",
        "NOT_APPLICABLE": "Not Applicable",
        "PENDING_PREDECESSORS": "Pending Predecessors",
        "READY_TO_START": "Ready to Start",
        "RETIRED": "Retired",
        "REVIEW_IN_PROGESS": "Review In Progress",
        "STARTED": "Started"
    },
    erisiteActions: {
        "Ready to Start": "StartandComplete",
        "Started": "Complete",
        "Completed": "StartandComplete",
        "Completed-Pending Approval": "StartandComplete",
        "Created": "StartandComplete",
        "Hold": "StartandComplete",
        "Not Applicable": "StartandComplete",
        "Pending Predecessors": "StartandComplete",
        "Retired": "StartandComplete",
        "Review In Progress": "StartandComplete",
        "Cancelled": "StartandComplete",
    },
    ehsMainIssues: [{ value: "Cancelled and Postponed", text: "Cancelled and Postponed" },
    { value: "No Team Response", text: "No Team Response" },
    { value: "Photo Not Allowed", text: "Photo Not Allowed" },
    { value: "Access Issues", text: "Access Issues" },
    { value: "Project Issue", text: "Project Issue" },
    { value: "Bad weather", text: "Bad weather" },
    { value: "No High Risk Activity", text: "No High Risk Activity" },
    { value: "Missed", text: "Missed" },
    { value: "Violations & Findings", text: "Violations & Findings" },
    { value: "CMS/DB Failed", text: "CMS/DB Failed" },
    { value: "No site Coverage", text: "No site Coverage" },
    { value: "Civil Issues", text: "Civil Issues" },
    { value: "Material Issue", text: "Material Issue" },
    { value: "Site Clear From Violation", text: "Site Clear From Violation" }
    ],

    nftpClassificationInsideEHS: [
        { value: "1", text: "Project Planning Issue (Project team)" },
        { value: "2", text: "Unmet EHS technical requirement (ASP)" },
        { value: "3", text: "Lack of operational control (EHS)" },
        { value: "4", text: "Unsafe Working Condition (Weather – Site)" },
        { value: "5", text: "Missing / Expired Documents (VO)" },
        { value: "6", text: "Missing / Unclear Picture (ASP)" },
        { value: "7", text: "Eritop Bug Issue (Dev. team)" }
    ],
    typeSites: [
        { value: 1, text: "Default" },
        { value: 2, text: "Expansion" },
        { value: 3, text: "Modernization" },
        { value: 6, text: "MW" },
        { value: 4, text: "New Site Build" },
        { value: 5, text: "Swap" }],

    WpTypes: [{ value: 1, text: "TX Link" }, { value: 2, text: "Radio Site" }],

    rscScopes: [
        {
            value: 1, text: "Remote Customer Acceptance"
        },
        {
            value: 2, text: "Remote Customer Acceptance (Ericsson - Customer)"
        },
        {
            value: 3, text: "Remote OHS Inspection"
        },
        {
            value: 4, text: "Remote Practical Assessment"
        },
        {
            value: 5, text: "Remote Quality Audit – VQA"
        },
        {
            value: 6, text: "Remote Site Acceptance (Supplier - Ericsson) – VPW"
        },
        {
            value: 7, text: "Remote Site Supervision"
        },
        {
            value: 8, text: "Remote Site Survey (pre-build)"
        },
        {
            value: 9, text: "VSS/VCOP"
        }
    ],
    erisiteUpdateError: "Erisite timeout error. Some data are updated successfuly, some are failed. Please try again later by using Update Erisite button to complete all update.",
    ticketSeverity: [
        { value: 1, text: "Low" },
        { value: 2, text: "High" },
        { value: 3, text: "Medium" },
        { value: 4, text: "Critical" }],
    ticketStatus: [
        { value: 1, text: "Pending" },
        { value: 2, text: "Resolved" },
        { value: 3, text: "Rejected" },
        { value: 4, text: "Assigned" },
        { value: 5, text: "Reopened" },
        { value: 6, text: "Customer Pending" }],
    actionTypes: { "ADD": 1, "EDIT": 2, "DELETE": 3 },
    ticketTabs: {
        "DEFAULT": 0,
        "ASSIGNED": 1,
        "UNASSIGNED": 2,
        "CLOSED": 3
    },
    qiIfDone: 29,
    numberOfSnags: 28,
    notAssignableSessionType: ["Remote Customer Acceptance", "Remote OHS Inspection"],

    hiddenWorkplans: ["CANCELLED", "AUTO CANCEL", "RETIRED"],
    imFilterTypes: {
        "ASSIGNED": "1",
        "UN_ASSIGNED": "2",
        "CLOSED": "3"
    },

    pills: [{ value: 1, text: "Assigned", color: "#E6F1EB" }, { value: 2, text: "Un-Assigned", color: "#F9E2E1" }],
    aspPmPills: [{ value: 1, text: "Assigned", color: "#E6F1EB" }, { value: 2, text: "Un-Assigned", color: "#F9E2E1" }, { value: 3, text: "Closed", color: "#EBEBEB" }],

    globalPlanningObject:
        [{ value: 1, text: "Perform Civil Works Survey" },
        { value: 2, text: "Civil Works Completed" },
        { value: 3, text: "Verify Civil Works Completion" },
        { value: 4, text: "Civil Works Accepted" },
        { value: 5, text: "Conduct Civil Works Acceptance with Customer" },
        { value: 6, text: "Perform Site Installation Survey" },
        { value: 7, text: "Site Installation Complete" },
        { value: 8, text: "Integration Complete" },
        { value: 9, text: "Execute Drive Test" },
        { value: 10, text: "Site On Air" },
        { value: 11, text: "Perform Site Implementation Acceptance" },
        { value: 12, text: "Conduct Site Implementation Acceptance with Customer" }].sort(PageHelper.dynamicSort("text")),

    ratingQuestions: [
        { value: 1, text: "Acceptance Pending Reason" },
        { value: 2, text: "Behavior Rating" },
        { value: 3, text: "Comment" },
        { value: 4, text: "Customer Acceptance Status" },
        { value: 5, text: "FTR  Way Of Check" },
        { value: 6, text: "FTR Status" },
        { value: 7, text: "IMK tool" },
        { value: 8, text: "Main Issue" },
        { value: 9, text: "NFTR Details" },
        { value: 10, text: "Number Of Snags/missing" },
        { value: 11, text: "Package Received" },
        { value: 12, text: " Pre - PAT By RSC" },
        { value: 13, text: "Pre - PAT FTR" },
        { value: 14, text: "QI if Done" },
        { valye: 15, text: "Quality Audit for 20 % of sites" },
        { value: 16, text: "RA Tools" },
        { value: 17, text: "RSC Acceptance Feedback" },
        { value: 18, text: "SDE tool" },
        { value: 19, text: "Site Status" },
        { value: 20, text: "SL Tools" },
        { value: 21, text: "Snag List" },
        { value: 22, text: "SRS Tools" },
        { value: 23, text: "Supervision Checklist" },
        { value: 24, text: "Technical Rating" },
        { value: 25, taext: "Tools Ref FB" }],
    ratingValue: { ftr: "FTR", readyForCustomerAcceptance: "READY FOR ACCEPTANCE" },
    incentives: {
        ericssonFtrWeightage: 30,
        ericssonFtrTarget: 85,
        noSnagsWeightage: 10,
        noSnagsTarget: 85,
        teamEfficiencyWeightage: 20,
        teamEfficiencyTarget: 85,
        readyForCustomerAcceptanceWightage: 20,
        readyForCustomerAcceptanceTarget: 85,
        ehsPerformanceWeightage: 20,
        ehsPerformanceAcceptanceTarget: 85
    },
    ehsDashboardConstants: {
        ehsPassWeightage: 30,
        ehsPassTarget: 85,
        ftpWeightage: 10,
        ftpTarget: 85,
        criticalViolationReportingWeightage: 20,
        criticalViolationReportingTarget: 15,
        rscReviewLtWightage: 20,
        rscReviewLtTarget: 85,
        overallEhsLtWeightage: 20,
        overallEhsLtTarget: 85
    },
    resourceStatus: { active: "Active" },
    customerSessionTypes: { "ERITOP": "ERITOP", "ECA": "ECA" },
    ecaSessionType: { "MANUAL": "Manual", "AUTO": "Auto" },
    jobsDict: {
        Rigger: 3,
        Driver: 13,
        EHS_Auditor : 18,
    },
    imActivitySessionType: "VSS/VCOP",
    complaintStatus: {
        "Opened": "1",
        "Acknowledged": "2",
        "Resolved": "3",
        "Closed": "4",
        "Cancelled": "5",
    },
    productivityWpTypes: [{ value: 1, text: "SWP" }, { value: 0.400, text: "EXP" }, { value: 0.667, text: "TRM" }, { value: 0.667, text: "NSB" }],
    installation: "Site Installation Complete",
    prePat: "Perform Site Implementation Acceptance",
    remoteSiteSupervision: "Remote Site Supervision",
    ecaConfigurations: { QA: 1, DEMO: 2, PROD: 3 },
    mfaDisabledList: [
        'vulnerability.scan.one@ericsson.com',
        'vulnerability.scan.two@ericsson.com',
        'el.mehdi.hajouji.idrissi@ericsson.com'
    ],
    FilterConversation: {All: 1, Activity: 2, EHS: 3},
    SendBack : [{id : 1 , name : "Send Back to Asp" },{id:2, name :"Send Back to Rsc Inspector"},{id:3, name :"Send Back to Rsc Inbox"}],
    DateType:
        [{ value: 1, text: "Eritop Actual Start" },
        { value: 2, text: "Eritop Actual End " }
        ].sort(PageHelper.dynamicSort("text"))

}

