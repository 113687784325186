import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiAutocompleteSelect from "../../../eui-components/select/autocompleteselect";
import EuiListBuilder from "../../../eui-components/listbuilder";
import { useEffect } from "react";
import EuiNewMultiSelect from "../../../eui-components/select/newmultiselect";

const Role2Content = (props) => {

    const countries = useSelector(state => state.user.countries);
    const roleEditMod = useSelector(state => state.userAdmin.roleEditMod);
    const userId = useSelector(state => state.user.userData.userId);

    const [aspCompanies, setAspCompanies] = useState(countries?.find(item => item.value === props.selectedRoleItems?.[0]?.countryId ?? 0)?.aspCompanies || []);
    const [countryId, setCountryId] = useState(props.selectedRoleItems?.[0]?.countryId ?? 0);

    const dispatch = useDispatch();

    useEffect(() => {
        
        
        if ((props.selectedRoleItems ?? []).length === 0 && props.selectedAction === 0) {
            setCountryId(0);
            setAspCompanies([]);
        }
    }, [props.selectedRoleItems]);

    const handleChange = (e, selectedItems) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        
        if (name === "countryId") {
            setCountryId(value);
            const companies = countries?.find(item => item.value === value)?.aspCompanies || [];
            setAspCompanies(companies);
        } else {
            addSelectedAspCompanies(e, selectedItems);
        }
    }

    const addSelectedAspCompanies = (e, selectedItems) => {
        if (selectedItems) {
            const mappedItems = selectedItems.map(c => {
                const aspCompany = aspCompanies && aspCompanies.find(item => item.text?.trim() === c.trim());
                aspCompany.aspCompanyId = aspCompany.value;
                aspCompany.id = 0;
                aspCompany.createdAt = new Date();
                aspCompany.updatedAt = new Date();
                aspCompany.createUserId = parseInt(userId);
                return aspCompany;
            });
            
            props.setSelectedRoleItems(mappedItems);
        }
    }

    return (
        <EuiRow>
            <EuiAutocompleteSelect
                disabled={!roleEditMod || countryId != 0}
                className="m-1 pr-0"
                divStyle={{ width: "100%" }}
                style={{ maxWidth: "100%", width: "48%", }}
                fullwidth
                label={{ title: "Select country to list companies", style: { fontWeight: 500 } }}
                id="userAdminRole2Countries"
                name="countryId"
                options={countries}
                value={countryId}
                onChange={(e) => handleChange(e)}
                placeholder="---Select Country---"
            />
            {!props.selectedRole.active ?
                <EuiListBuilder
                    addButtonId="role2AddButtonId"
                    removeButtonId="role2RemoveButtonId"
                    id="userAdminRoleTwoListBuilder"
                    availableItem={{ title: "Available ASP Companies", hideSelectAll: true }}
                    assignedItem={{ title: "Assigned ASP Companies", hideSelectAll: true }}
                    data={aspCompanies}
                    addSelectedItems={(e, selectedItems) => addSelectedAspCompanies(e, selectedItems)}
                /> :
                <EuiNewMultiSelect
                    className="m-1 "
                    style={{ maxWidth: "100%", width: "47%" }}
                    disabled={!roleEditMod || countryId === 0}
                    id={"multiSelect_" + props.selectedRole.role}
                    placeholder="---Select ASP Companies---"
                    name="assignedCustomerRole2"
                    options={aspCompanies}
                    value={props.selectedRoleItems.map(i => i.text)}
                    onChange={(e, options) => handleChange(e, options)}
                />
            }

        </EuiRow>
    );

}

export default Role2Content;