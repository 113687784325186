import React, { useState, useEffect } from "react";
import { Tree } from '@eds/vanilla';
import EuiTile from "./tile";

const EuiGenericTree = (props) => {

    useEffect(() => {
        if (props.id) {
            const trees = document.querySelectorAll('#' + props.id);
            if (trees) {
                Array.from(trees).forEach((treeDOM) => {
                    const tree = new Tree(treeDOM);
                    tree.init();
                });
            }
        }
        else {
            console.error("You should set an id to Listbuilder component");
        }
    }, [props.data]);

    return (
        <div className={"tile fullscreen sm-12 lg-12 xl-12" + (props.viewTree ? "" : " hidden")} id="dataTreeId" style={{ padding: 10, height: "100%" }}>
            < div className="full-width" >
                {(props.filter ?
                    <div className="full-width">
                        <input id="search" type="text" className="with-icon flex-middle-content fullwidth "
                            onChange={props.onFilterChange}
                            placeholder="Type to filter..." />
                        <div className="suffix icon-inside"><i className="icon icon-filter"></i></div>
                    </div>
                    : null)
                }
                < div className="tree full-width mt-3" style={{ overflowY: "scroll", maxHeight: "1000px" }} >
                    {props.data?.map(e =>
                        <ul className="mb-0">
                            <li ><span id={e.id} className={"title item " + e.state + " "}
                                onClick={() => props.onItemClicked(e.id)}
                            >
                                {e.element}
                            </span>
                                {(e.subList ?
                                    e.subList.map(c => <ul>
                                        <li >
                                            <span className={"item " + c.state} style={{ display: "flex", paddingLeft: "30px", paddingRight: "0px", paddingBottom: "0px", paddingTop: "0px" }}
                                                onClick={() => props.onSubItemSelect(c.value, e.element)}
                                            >
                                                <span className="flex-start-content w-10"   >
                                                    <span className="w-8">
                                                        <i className="icon icon-chevron-right" />
                                                        {c.text}
                                                    </span>
                                                </span>
                                            </span>

                                        </li>

                                    </ul>)
                                    : null)}
                            </li>
                        </ul>)
                    }
                </div>
            </div>
        </div >)
}

export default EuiGenericTree;
